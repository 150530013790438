import React, { useState, useEffect, useRef, useMemo } from 'react'
import ReactPlayer from 'react-player'

import config from '../_config'
import utils from '../_utils'

const { tracks } = config
const { handleHand, getStylesForAudioPlayer, getTimeString } = utils

const allSlugs = tracks.map(i => i.slug)

let trackNumber

const AudioPlayer = ({ dispatch, currentTrack, coords, isHandPointing, veilOpacity }) => {
    //I'm using useRef instead of the callback ref pattern    ref={ref => <refName> = ref}    for performance purposes. Refs with useRef return a mutable ref object whose .current property is initialized to the argument passed to it, in this case, null. The returned object will persist for the full lifetime of the component providing a consistent way to access the refs. The callback ref pattern on the other hand will be called more than once during updates, as it gets called first with null and then again with the DOM element each time the component rerenders. useRef however doesn't cause a rerender when the ref object is mutated, which is beneficial for performance when you need to frequently update the ref without needing to update the component visually. The callback ref pattern might lead to performance hits if not managed carefully especially if the ref callback triggers rerenders or is tied to high-frequency events
    const reactPlayerRef = useRef(null)
    const playButtonRef = useRef(null)
    const stopButtonRef = useRef(null)
    const pauseButtonRef = useRef(null)
    const forwardButtonRef = useRef(null)
    const backwardButtonRef = useRef(null)

    //State variables to manage track information and playback status for AudioPlayer's UI as well as the ReactPlayer component
    const [trackLength, setTrackLength] = useState(0)
    const [totalSeconds, setTotalSeconds] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [hasEnded, setHasEnded] = useState(true)


    const handlePlayer = (type, e) => { //This handlePlayer function is called by the various buttons on AudioPlayer's UI to manage the local state of the UI, the state of the ReactPlayer component and the state of currentTrack in Home. It takes two arguments, a mandatory type string and an optional event object (which is only used when type is 'slidePlaybackAndUpdatePlaybackTime')...
        switch (type) {
            case 'stop': //...if type is 'stop'...
                setTotalSeconds(0) //...reset totalSeconds to 0...
                setIsPlaying(false) //...stop playing...
                setHasEnded(true) //...mark the track as having ended...
                reactPlayerRef.current.seekTo(0) //...and bring ReactPlayer's state of track position back to the beginning...
                break
            case 'play': //...if type is 'play'...
                if (Object.keys(currentTrack).length === 0) { //...if there's no currentTrack...
                    setTotalSeconds(0) //...reset totalSeconds to 0...
                    setIsPlaying(false) //...stop playing...
                    setHasEnded(true) //...mark the track as having ended...
                    trackNumber = allSlugs.indexOf(currentTrack.slug)  //...get the index of the currentTrack in the array of all tracks...
                    dispatch({type: 'setCurrentTrack', currentTrack: tracks[(trackNumber + 1) % 10]}) //...and set the currentTrack to the next track in the array of all tracks...
                }
                setIsPlaying(true) //...and either way start playing...
                setHasEnded(false) //...and mark the track as having not ended...
                break
            case 'pause': //...if type is 'pause'...
                if (!hasEnded) { //...and if the track has not ended...
                    setIsPlaying(false) //...stop playing...
                }
                break
            case 'forward': //...if type is 'forward'...
                setTotalSeconds(0) //...reset totalSeconds to 0...
                setIsPlaying(false) //...stop playing...
                setHasEnded(true) //...mark the track as having ended...
                trackNumber = allSlugs.indexOf(currentTrack.slug) //...get the index of the currentTrack in the array of all tracks...
                if (coords) { //...and only if props.coords is passed, meaning the user is using AudioPlayer from Home.js and not SingleTrack.js...
                    dispatch({type: 'setCurrentTrack', currentTrack: tracks[(trackNumber + 1) % 10]}) //...set the currentTrack to the next track in the array of all tracks...
                }
                break
            case 'backward': //...if type is 'backward'...
                setTotalSeconds(0) //...reset totalSeconds to 0...
                setIsPlaying(false) //...stop playing...
                setHasEnded(true) //...mark the track as having ended...
                trackNumber = allSlugs.indexOf(currentTrack.slug) //...get the index of the currentTrack in the array of all tracks...
                if (trackNumber > 0) { //...if the current track is not the first track in the array of all tracks...
                    dispatch({type: 'setCurrentTrack', currentTrack: tracks[(trackNumber - 1) % 10]}) //...set the currentTrack to the previous track in the array of all tracks...
                } else { //...otherwise...
                    dispatch({type: 'setCurrentTrack', currentTrack: {}}) //...reset the currentTrack to an empty object, which is its initial state when the application first loads...
                    setTrackLength(0) //...and consequently reset trackLength to 0...
                }
                break
            case 'updatePlaybackTime': //...if type is 'updatePlaybackTime'...
                setTotalSeconds(isPlaying ? totalSeconds + 1 : totalSeconds) //...increment totalSeconds by 1 if the track is playing, otherwise leave it as is...
                break
            case 'slidePlaybackAndUpdatePlaybackTime': //...if type is 'slidePlaybackAndUpdatePlaybackTime'...
                if (e) { //...and if an event object was passed, which if type is 'slidePlaybackAndUpdatePlaybackTime' it always will be but this check must be here nonetheless because though handlePlayer of type 'slidePlaybackAndUpdatePlaybackTime' is uniquely called only when engaging the slider on the UI occasionally it's called programmatically with rerenders of AudioPlayer, i.e. without any user interaction and therefore without an event object...
                    reactPlayerRef.current.seekTo(e.target.value) //...bring ReactPlayer's state of track position to the value of the slider...
                    setTotalSeconds(parseInt(e.target.value)) //...and set totalSeconds to the value of the slider...
                }
                break
            default: //...and in the impossible case that handlePlayer is called without a type do nothing
                break
        }
    }


    const { styles, classNames } = useMemo(() => getStylesForAudioPlayer(veilOpacity, isPlaying, hasEnded, !!coords), [veilOpacity, isPlaying, hasEnded, !!coords]) //Memoize the styles and classNames for the AudioPlayer's UI based on the current state of AudioPlayer and ReactPlayer, and on the presence of props.coords, which is used to determine whether the user is using AudioPlayer from Home.js or from SingleTrack.js, and which is intentionally passed with a shebang to coerce it to a boolean thereby preventing the otherwise rapid unnecessary rerenders that would occur if only coords were passed


    useEffect(() => { //With props.currentTrack in the dependency array, if the currentTrack has changed with a new track...
        if (Object.keys(currentTrack).length > 0 && coords) { //...if the currentTrack is not an empty object, meaning the component hasn't just loaded, and props.coords was passed, meaning the user is using AudioPlayer from Home.js and not SingleTrack.js...
            setTimeout(() => {
                if (trackLength === 0) { //...if trackLength is still 0, even through the currentTrack is not an empty object, it means the track just loaded exactly 1 second ago because ReactPlayer's progressInterval is 1000ms...
                    setTotalSeconds(0) //...so totalSeconds must be set to 0 for the track to start at 0...
                } else { //...otherwise it means ReactPlayer will engage immediately, calling handlePlayer('updatePlaybackTime') from its onProgress prop, thereby incrementing totalSeconds by 1...
                    setTotalSeconds(-1) //...so totalSeconds must be set to -1 for the track to start at 0...
                }
            }, 0) //...and this must happen in a setTimeout so as to push the operation to the bottom of the execution stack, otherwise if the slider was moved by the user on the previously played track and the track since changed to another track by a call to dispatch of type 'setCurrentTrack' the slider would wind up in the wrong place..
            handlePlayer('play') //...and play the track
        }
    }, [currentTrack])

    useEffect(() => { //Effect to handle hand gestures for play, stop, pause, forward, and backward
        if (isHandPointing) {
            handleHand(coords, playButtonRef.current, () => handlePlayer('play'), 300)
            handleHand(coords, stopButtonRef.current, () => handlePlayer('stop'), 300)
            handleHand(coords, pauseButtonRef.current, () => handlePlayer('pause'), 300)
            handleHand(coords, forwardButtonRef.current, () => handlePlayer('forward'), 300)
            handleHand(coords, backwardButtonRef.current, () => handlePlayer('backward'), 300)
        }
    }, [coords])


    return (
        <div style={styles.parentContainer}>

            <div style={styles.buttonContainer}>

                { !coords ? null : <i onClick={() => handlePlayer('backward')} style={styles.icon} className={classNames.backward} ref={backwardButtonRef}></i> }
                <i onClick={() => handlePlayer('play')} style={styles.icon} className={classNames.play} ref={playButtonRef}></i>
                <i onClick={() => handlePlayer('pause')} style={styles.icon} className={classNames.pause} ref={pauseButtonRef}></i>
                <i onClick={() => handlePlayer('stop')} style={styles.icon} className={classNames.stop} ref={stopButtonRef}></i>
                { !coords ? null : <i onClick={() => handlePlayer('forward')} style={styles.icon} className={classNames.forward} ref={forwardButtonRef}></i> }

            </div>

            <p style={styles.timeString}>{getTimeString(totalSeconds)}</p>

            <input style={styles.slider} className='slider' type='range' min='0' max={trackLength} value={totalSeconds} onChange={e => handlePlayer('slidePlaybackAndUpdatePlaybackTime', e)}/>

            <ReactPlayer
                ref={reactPlayerRef}
                url={currentTrack.url}
                playing={isPlaying}
                progressInterval={1000}
                onProgress={() => handlePlayer('updatePlaybackTime')}
                onEnded={() => handlePlayer('forward')}
                onDuration={duration => setTrackLength(duration)}
            />

        </div>
    )
}

export default AudioPlayer
